import { Container, Flex, Heading } from "@chakra-ui/react";
import { type LinksFunction } from "@remix-run/cloudflare";
import { useLoaderData } from "@remix-run/react";
import { AwsClient } from "aws4fetch";
import stylesheet from "../styles/_index.css";

export const links: LinksFunction = () => {
  return [{ href: stylesheet, rel: "stylesheet" }];
};

export async function loader({
  context


}: {context: RequestContext;}): Promise<string[]> {
  if (context.request.headers.get("Save-Data") === "on") return [];

  const s3base = `https://car-crushers.${context.env.R2_ZONE}.r2.cloudflarestorage.com`;

  const baseURLs = [
  `${s3base}/trailer.webm?X-Amz-Expires=600`,
  `${s3base}/trailer.mp4?X-Amz-Expires=600`];


  const aws = new AwsClient({
    accessKeyId: context.env.R2_ACCESS_KEY,
    secretAccessKey: context.env.R2_SECRET_KEY
  });

  const urls = [];

  for (let i = 0; i < baseURLs.length; i++) {
    urls.push(
      (
      await aws.sign(baseURLs[i], {
        aws: {
          signQuery: true
        }
      })).
      url
    );
  }

  return urls;
}

export default function () {
  const sourceURLs = useLoaderData<typeof loader>();

  return (
    <>
      <video autoPlay={true} id="home-video">
        <source src={sourceURLs[0]} type="video/webm" />
        <source src={sourceURLs[1]} type="video/mp4" />
      </video>
      <Container
        maxW="container.lg"
        paddingTop="8vh"
        position="relative"
        textAlign="left"
        zIndex="1">

        <Flex>
          <Heading>Fueling Destruction since 2012</Heading>
        </Flex>
      </Container>
    </>);

}